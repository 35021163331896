import "./home.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";

import { Container, Row, Col } from "react-bootstrap";

import HeroSwiper from "components/HeroSections/HomeHeroLoginSwiper";
import Programs from "components/Courses/Programs";
import Courses2 from "components/Courses/CoursesGroup2";
import DateSection from "components/Date/DateSection";

import { useNavigate } from "react-router-dom";

// data
import localData from "data/data.json";
let pageData = localData.home;

export default function Home(props) {
  // const [homeData, setHomeData] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [specialCourses, setSpecialCourses] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // redirect to login if user is not logged in
    if (!localStorage.getItem("user-info")) {
      navigate("/login");
    }
    getPrograms();
    // getHomeData();
    getSpecialCourses();
  }, []);

  // Fetching programs from Api
  const getPrograms = async () => {
    axios
      .get("programs")
      .then(function (response) {
        setPrograms(response.data.programs);

        // console.log(programs);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  // Fetching home data
  const getHomeData = async () => {
    axios
      .get("homepage")
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  // Fetch Special courses
  const getSpecialCourses = async () => {
    axios
      .get("special_courses")
      .then(function (response) {
        setSpecialCourses(response.data.data.special_courses);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };
  return (
    <>
      <section className="ts-hero-logged ">
        <Container>
          <HeroSwiper />
        </Container>
      </section>
      <section className="ts-reham-element ts-section-py">
        <Container>
          <Programs
            CoursesTitle={
              props.rtlSwitch
                ? pageData.program_title_ar
                : pageData.program_title_en
            }
            ProgramsList={programs}
            rtlSwitch={props.rtlSwitch}
            isLogged={props.isLogged}
          />
        </Container>
      </section>
      {/* hide section when special offer's are not available */}
      {specialCourses.length > 0 ? (
        <section className="ts-reham-element ts-section-py">
          <Container>
            <Courses2
              CoursesTitle={
                props.rtlSwitch
                  ? pageData.specialoffer_title_ar
                  : pageData.specialoffer_title_en
              }
              SpecialCourseslist={specialCourses}
              rtlSwitch={props.rtlSwitch}
            />
          </Container>
        </section>
      ) : (
        ""
      )}

      <section className="ts-section-py">
        <Container>
          <Row className=" gap-5 gap-lg-0">
            <Col sm={12} lg={6}>
              <div
                className={`ts-contact-poster ts-rounded ${props.rtlSwitch
                  ? "ts-rounded--not-bottom-right"
                  : "ts-rounded--not-top-left"
                  }`}
              >
                <h3 className="text-white fw-normal">
                  {props.rtlSwitch
                    ? pageData.contact_poster_text_ar
                    : pageData.contact_poster_text_en}
                </h3>
              </div>
            </Col>
            <Col sm={12} lg={6} className="h-auto">
              <DateSection rtlSwitch={props.rtlSwitch} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
