import "./cart.scss";
import React, { useState, useEffect } from "react";
// Axios
import axios from "helpers/AxiosConfig";
// Bootstrap
import {
  Container,
  Stack,
  Form,
  Row,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
// Components
import EmptyCart from "./CartEmpty";
import CheckOutCardsGroup from "components/Cards/CheckOutCardsGroup";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// data

import localData from "data/data.json";
import { tr } from "date-fns/esm/locale/tr/index.js";
let pageData = localData.cart;

export default function Cart(props) {
  const [isEmpty, setIsEmpty] = useState(true);
  const [rateInUsd, setRateInUsd] = useState(false);
  const [paypalRate, setPaypalRate] = useState(1);
  const [paymentMethodVal, setPaymentMethodVal] = useState(false);
  const [subTotalSalesFees, setsubTotalSalesFees] = useState(0);
  const [couponCodeValue, setCouponCodeValue] = useState(0);
  const [couponCodeAmount, setCouponCodeAmount] = useState(0);
  const [iscouponCodeValid, setIsCouponCodeValid] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [affiliateCode, setAffiliateCode] = useState(null);
  const [totalSalesFees, setTotalSalesFees] = useState(
    subTotalSalesFees - couponCodeAmount
  );

  // Loading
  const [couponSpinnerLoading, setCouponSpinnerLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Getting paypal rate
  useEffect(() => {
    axios
      .get("paypal_rate")
      .then((response) => {
        setPaypalRate(response.data.data.paypal_rate);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, [props.cartData]);

  useEffect(() => {
    setAffiliateCode(localStorage.getItem("affiliate-code"));

    if (props.cartData.length > 0) {
      setIsEmpty(false);
      totalFeesCalculator(props.cartData);
      // Delete course id from local storage after added to cart api
      localStorage.removeItem("add_to_Cart_courses");
    } else {
      setIsEmpty(true);
    }
  }, [props.cartData]);

  const totalFeesCalculator = (courses, couponCodeAmount = 0) => {
    let totalFeesSales = 0;
    for (let course in courses) {
      let courseSaleFees = courses[course].course_fees[0].sale_fee;
      totalFeesSales += courseSaleFees;
    }
    setsubTotalSalesFees(totalFeesSales);
    setTotalSalesFees(totalFeesSales - couponCodeAmount);
  };

  const handlePayment = (event) => {
    event.preventDefault();

    let stringOfCourseIds = "";

    for (let course = 0; course < props.cartData.length; course++) {
      if (course === props.cartData.length - 1) {
        stringOfCourseIds += props.cartData[course].id;
      } else {
        stringOfCourseIds += props.cartData[course].id + ",";
      }
    }
    if (paymentMethodVal === 0) {
      axios
        .post("checkout", {
          courses: stringOfCourseIds,
          payment_type: "knet",
          coupon_id: couponId,
          affiliate_code: affiliateCode,
        })
        .then((response) => {
          // Redirect to Knet payment link
          let KnetPaymentLink = response.data.url;
          window.location.href = KnetPaymentLink;
        })
        .catch(function (error) {
          console.log(error); // handle error
        });
    }
    if (paymentMethodVal === 1) {
      axios
        .post("checkout", {
          courses: stringOfCourseIds,
          payment_type: "credit_card",
          coupon_id: couponId,
          affiliate_code: affiliateCode,
        })
        .then((response) => {
          // Redirect to credit  payment link
          let creditCartLink = response.data.url;
          window.location.href = creditCartLink;
        })
        .catch(function (error) {
          console.log(error); // handle error
        });
    }
    if (paymentMethodVal === 2) {
      axios
        .post("checkout", {
          courses: stringOfCourseIds,
          payment_type: "paypal",
          coupon_id: couponId,
          affiliate_code: affiliateCode,
        })
        .then((response) => {
          // Redirect to credit  payment link
          let creditCartLink = response.data.url;
          window.location.href = creditCartLink;
        })
        .catch(function (error) {
          console.log(error); // handle error
        });
    }
  };

  let updatePaymentMethod = (e) => {
    setPaymentMethodVal(parseInt(e.target.id));
    if (parseInt(e.target.id) === 2) {
      setRateInUsd(true);
    } else {
      setRateInUsd(false);
    }
  };
  const checkCouponCode = () => {
    setIsCouponCodeValid(false);
    setCouponSpinnerLoading(true);
    axios
      .post("check_coupon", {
        code: couponCodeValue,
      })
      .then((response) => {
        let data = response.data.data;
        let status = response.status;
        console.log(data);
        if (status === 200) {
          let amount = data.amount;
          totalFeesCalculator(props.cartData, amount);
          setCouponId(data.id);
          setIsCouponCodeValid(true);
          setCouponCodeAmount(amount);
          toast.success(`Coupon Code is valid amount of : ${amount}`, {
            position: "bottom-right",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setCouponSpinnerLoading(false);
      })
      .catch(function (error) {
        let message = error;
        // console.error(message);
        toast.error(message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error); // handle error
      });
  };
  let paymentMethod = props.rtlSwitch
    ? pageData.payment_methods_ar
    : pageData.payment_methods_en;

  return (
    <section
      className={`ts-cart ${isEmpty ? "ts-cart--empty" : "ts-cart--filled"}`}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Container>
        {isEmpty ? (
          <EmptyCart isLogged={props.isLogged} />
        ) : (
          <Row className="gap-5 gap-xl-0">
            <Col sm={12} xxl={8}>
              <Stack direction="horizontal" gap={3} className="mb-07">
                <div>
                  <img
                    className="ts-cart__img mb-05"
                    src="assets/icons/shopping-cart.svg"
                    alt="shopping cart icon"
                  />
                </div>
                <div>
                  <h1>
                    {props.rtlSwitch ? pageData.title_ar : pageData.title_en}
                  </h1>
                  <p className="ts-desc-lg mb-0">
                    {props.cartData.length}&nbsp;
                    {props.rtlSwitch ? pageData.items_ar : pageData.items_en}
                  </p>
                </div>
              </Stack>

              <CheckOutCardsGroup
                groupCol="2"
                rtlSwitch={props.rtlSwitch}
                products={props.cartData}
                removeFromCart={props.removeFromCart}
                rateInUsd={rateInUsd}
                paypalRate={paypalRate}
              />
            </Col>
            <Col sm={12} xxl={4} className="mb-05 mt-xl-5">
              <Form className="ts-cart-summery" onSubmit={handlePayment}>
                <h3 className="mb-07">
                  {props.rtlSwitch
                    ? pageData.checkout_title_ar
                    : pageData.checkout_title_en}
                </h3>
                <Stack
                  direction="horizontal"
                  className="justify-content-between mb-07"
                >
                  <p className="ts-desc-md mb-0">
                    {props.rtlSwitch
                      ? pageData.item_quantity_ar
                      : pageData.item_quantity_en}
                  </p>
                  <h5 className="mb-0">{props.cartData.length}</h5>
                </Stack>
                <hr className="mb-08" />

                <Stack direction="horizontal" gap="3" className="mb-05">
                  <Form.Group controlId="tsCoupon" className="w-100">
                    <Form.Control
                      type="text"
                      size="lg"
                      placeholder={
                        props.rtlSwitch
                          ? pageData.coupon.input_placeholder_ar
                          : pageData.coupon.input_placeholder_en
                      }
                      onChange={(e) => setCouponCodeValue(e.target.value)}
                    />
                  </Form.Group>

                  {couponSpinnerLoading ? (
                    <div>
                      <Spinner
                        width="20"
                        animation="border"
                        variant="primary-02"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <Button
                    variant="primary-01"
                    className="h-100 px-0"
                    onClick={checkCouponCode}
                  >
                    {props.rtlSwitch
                      ? pageData.coupon.button_ar
                      : pageData.coupon.button_en}
                  </Button>
                </Stack>

                <Stack
                  direction="horizontal"
                  className="justify-content-between mb-02"
                >
                  <p className="ts-desc-sm mb-0">
                    {props.rtlSwitch
                      ? pageData.summery.subtotal_ar
                      : pageData.summery.subtotal_en}
                  </p>
                  <p className="ts-desc-sm mb-0">
                    {(subTotalSalesFees * (rateInUsd ? paypalRate : 1)).toFixed(
                      2
                    )}
                    {rateInUsd ? "USD" : "KD"}{" "}
                  </p>
                </Stack>
                <Stack
                  direction="horizontal"
                  className="justify-content-between "
                >
                  <p className="ts-desc-sm mb-0">
                    {props.rtlSwitch
                      ? pageData.summery.coupon_discount_ar
                      : pageData.summery.coupon_discount_en}
                  </p>
                  <p className="ts-desc-sm mb-0">
                    {(couponCodeAmount * (rateInUsd ? paypalRate : 1)).toFixed(
                      2
                    )}

                    {rateInUsd ? "USD" : "KD"}
                  </p>
                </Stack>
                <hr />
                <Stack
                  direction="horizontal"
                  className="justify-content-between mb-07"
                >
                  <p className="ts-desc-md mb-0">
                    {props.rtlSwitch
                      ? pageData.summery.total_ar
                      : pageData.summery.total_en}
                  </p>
                  <p className="ts-desc-md mb-0">
                    {(totalSalesFees * (rateInUsd ? paypalRate : 1)).toFixed(2)}
                    {rateInUsd ? "USD" : "KD"}
                  </p>
                </Stack>

                <ul className="ts-payment-methods mb-07  p-0">
                  {paymentMethod.map((method, index) => (
                    <li
                      key={`payment-method-${index}`}
                      className="ts-payment-methods__item"
                    >
                      <Form.Check
                        className="me-0"
                        inline
                        label={method}
                        name="paymentMethod"
                        type="radio"
                        id={`${index}`}
                        onChange={(e) => updatePaymentMethod(e)}
                      />
                    </li>
                  ))}
                </ul>
                <Button
                  type="submit"
                  variant="primary-04"
                  className={`btn--checkout w-100 fw-bold ${paymentMethodVal === false ? "disabled ts-disabled" : ""
                    }`}
                >
                  {props.rtlSwitch
                    ? pageData.checkout_btn_ar
                    : pageData.checkout_btn_en}
                </Button>
              </Form>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
}
